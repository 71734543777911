import { mapState, mapActions } from 'vuex'

const wheelStore = {
    store: window.store,
    computed: {
        ...mapState('wheel', {
            currentStep: (state) => state.currentStep,
            isTransition: (state) => state.isTransition,
            transitionState: (state) => state.transitionState,
        }),
    },
    methods: {
        handleMove(dir, id) {
            this.moveIndexSection({ step: id, direction: dir })
        },
        handleMoveState(dir, current) {
            this.moveIndexSectionState({ direction: dir, isMove: current })
        },
        handleisMove(current) {
            this.move({ isMove: current })
        },
        ...mapActions('wheel', [
            'move',
            'moveIndexSection',
            'moveIndexSectionState',
        ]),
    },
}
export default wheelStore
