import {mapState, mapActions} from 'vuex'
import Swiper, {Autoplay, Navigation, EffectFade, Grid} from 'swiper'
import {gsap} from 'gsap'
import bgstyleMixin from '../mixin/helpers/bgstyle_mixin'
import wheelMixin from '../mixin/helpers/wheel_mixin'
import notificationMixin from '../mixin/public/notification_mixin'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import * as echarts from 'echarts';
import axios from 'axios';
import  CountUp  from 'countUp';
Swiper.use([Autoplay, Navigation, EffectFade, Grid]);

gsap.registerPlugin(ScrollTrigger)

gsap.defaults({ease: 'power1.out', duration: 0.2});
let dataJson;

dataJson = JSON.parse(document.getElementById('dataJson').textContent)
new Vue({
    el: '#index',
    name: 'Index',
    data() {
        return {
            news:dataJson.news,
            events:dataJson.events,
            events:dataJson.events,
            banner_artistic:dataJson.banner_artistic, 
            footer_up:dataJson.footer_up, 
            footer_down:dataJson.footer_down, 
            w_h:window.innerHeight,
            e1_status:false,
            e2_status:false,
            e3_status:false
        }
    },
    mixins: [bgstyleMixin, wheelMixin, notificationMixin],
    delimiters: ['${', '}'],
    computed: {
        ...mapState('defaults', {
            currentedPage: (state) => state.currentedPage,
            isLoading: (state) => state.isLoading,
        }),
    },
    created() {

    },
    destroyed() {
    },
    methods: {
        ...mapActions('defaults', [
            'handleLoading',
        ]),
        initChart(){
            let t_h = document.getElementById('chart_area').getBoundingClientRect().top;
            let e1_h =  document.getElementById('echart_1').getBoundingClientRect().top;
            let e2_h =  document.getElementById('echart_2').getBoundingClientRect().top;
            let e3_h =  document.getElementById('echart_3').getBoundingClientRect().top;
            if(e1_h<this.w_h && !this.e1_status){
                let echart_1 = echarts.init(document.getElementById('echart_1'));
                echart_1.setOption({
                    title: {
                        text: '',
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    grid: {
                        left: '15%'
                    },
                    legend: {
                        top: '5%',
                        left: 'center',
                        textStyle: {
                            color: '#fff'
                        },

                    },
                    series: [
                        {
                            //name: 'Access From',
                            type: 'pie',
                            top:'20%',
                            radius: ['50%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 0,
                                borderColor: '#203051',
                                borderWidth: 0
                            },
                            label: {
                                show: false,
                                position: 'center',
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold',
                                    color:'#fff'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 354, name: '大西洋'},
                                {value: 390, name: '東北亞'},
                                {value: 1686, name: '東亞'},
                                {value: 998, name: '東南亞'},
                                {value: 116, name: '非洲'},
                                {value: 457, name: '美洲'},
                                {value: 210, name: '歐洲'}
                            ]
                        }
                    ]
                });
                this.e1_status = !this.e1_status;

            }
            if (e2_h<this.w_h && !this.e2_status){
                let options = {
                    useEasing : true,
                    useGrouping : true,
                    separator : ',',
                    decimal : '.',
                    prefix : '',
                    suffix : ''
                };
                let alumni_num = new CountUp("alumni_num", 0, 154361, 0, 2.5, options);
                alumni_num.start();
                this.e2_status = !this.e2_status;
            }
            if(e3_h<this.w_h && !this.e3_status){
                let echart_3 = echarts.init(document.getElementById('echart_3'));
                echart_3.setOption({
                    title: {
                        text: '',
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    grid: {
                        left: '15%'
                    },
                    legend: {
                        top: '5%',
                        left: 'center',
                        textStyle: {
                            color: '#fff'
                        },
                        data: ['男生', '女生']
                    },
                    yAxis: {
                        axisLine: {
                            lineStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    xAxis: {
                        data: ['金質獎', '銀質獎', '銅質獎'],
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: false },
                        axisLine: {
                            lineStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    series: [
                        {
                            name: '男生',
                            type: 'bar',
                            stack: 'one',
                            data: [350, 650, 3026],
                            itemStyle: {
                                color: "rgba(79, 129, 189, 1)"
                            }
                        },
                        {
                            name: '女生',
                            type: 'bar',
                            stack: 'one',
                            data: [660, 821, 3407],
                            itemStyle: {
                                color: "rgba(192, 80, 77, 1)"
                            }
                        }
                    ]
                    // series: [
                    //     {
                    //         //name: 'Access From',
                    //         type: 'pie',
                    //         top:'20%',
                    //         radius: ['50%', '70%'],
                    //         avoidLabelOverlap: false,
                    //         itemStyle: {
                    //             borderRadius: 0,
                    //             borderColor: '#203051',
                    //             borderWidth: 0
                    //         },
                    //         label: {
                    //             show: false,
                    //             position: 'center',
                    //             color: 'rgba(255, 255, 255, 0.3)'
                    //         },
                    //         emphasis: {
                    //             label: {
                    //                 show: true,
                    //                 fontSize: '40',
                    //                 fontWeight: 'bold',
                    //                 color:'#fff'
                    //             }
                    //         },
                    //         labelLine: {
                    //             show: false
                    //         },
                    //         data: [
                    //             {value: 1048, name: 'Search Engine'},
                    //             {value: 735, name: 'Direct'},
                    //             {value: 580, name: 'Email'},
                    //             {value: 484, name: 'Union Ads'},
                    //             {value: 300, name: 'Video Ads'}
                    //         ]
                    //     }
                    // ]
                });
                this.e3_status = !this.e3_status;
                window.removeEventListener("scroll", this.initChart);
            }
            if (t_h<this.w_h) {

                //let echart_2 = echarts.init(document.getElementById('echart_2'));


                // echart_1.setOption({
                //     title: {
                //         text: '',
                //     },
                //     grid: {
                //         left: '15%'
                //     },
                //     xAxis: {
                //         data: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },
                //     yAxis: {
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },
                //     series: [
                //         {
                //             name: '總人數',
                //             type: 'bar',
                //             barWidth:'80%',
                //             data: [460, 268, 716, 571, 625, 550, 846, 867, 952, 1128, 1253],
                //             label: {
                //                 show: false,
                //                 position: 'top',

                //             },
                //             bottom:200

                //         }
                //     ],
                // });


                // echart_2.setOption({
                //     title: {
                //         text: '',
                //         textStyle: {
                //             color: '#fff'
                //         }
                //     },
                //     tooltip: {
                //         trigger: 'item'
                //     },
                //     grid: {
                //         left: '20%'
                //     },
                //     legend: {
                //         top: '5%',
                //         left: 'center',
                //         textStyle: {
                //             color: '#fff'
                //         },
                //         data: ['童軍人數']
                //     },
                //     yAxis: {
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },
                //     xAxis: {
                //         data: ['86-90', '91-95', '96-100', '101-105', '106-110'],
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },
                //     series: [
                //         {
                //             name: '童軍人數',
                //             type: 'bar',
                //             stack: 'one',
                //             data: [4950, 9900, 14850, 19800, 24750],
                //             itemStyle: {
                //                 color: "rgb(201,255,189)"
                //             }
                //         },
                //     ]
                // });
                // echart_3.setOption({
                //     title: {
                //         text: '',
                //         textStyle: {
                //             color: '#fff'
                //         }
                //     },
                //     grid: {
                //         left: '20%'
                //     },
                //     xAxis: {
                //         type: 'category',
                //         boundaryGap: false,
                //         data: ['106', '107', '108', '109', '110'],
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },

                //     tooltip: {
                //         trigger: 'item',
                //         formatter:'{b0}學年度<br>{a0}: {c0}'
                //     },
                //     legend: {
                //         top: '5%',
                //         left: 'center',
                //         textStyle: {
                //             color: '#fff'
                //         }
                //     },
                //     yAxis: {
                //         type: 'value',
                //         axisLine: {
                //             lineStyle: {
                //                 color: "#fff"
                //             }
                //         }
                //     },
                //     series: [
                //         {
                //             data: [1335,2240,3034,3728,4441],
                //             name: '累進志工人數',
                //             type: 'line',
                //             areaStyle: {
                //             },
                //             smooth: true,
                //             label:{
                //                 //show:true
                //             },
                //             emphasis: {
                //                 label: {
                //                     show: true,
                //                 }
                //             },
                //         }
                //     ]
                // });
                // echart_1.setOption({
                //     title: {
                //         text: '',
                //         textStyle: {
                //             color: '#fff',
                //             lineHeight: 50,
                //             fontWeight: 100
                //         }
                //     },
                //     grid: {
                //         left: '15%'
                //     },
                //     tooltip: {
                //         trigger:'axis',
                //         formatter:'{b0}({a0}): {c0}<br/>{b1}({a1}): {c1}'
                //     },

                //     xAxis: {
                //         data: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018',],
                //         axisLine:{
                //             lineStyle:{
                //                 color:'#fff',

                //             }
                //         }
                //     },
                //     yAxis: [{
                //         type:'value',
                //         show:true,
                //         axisLine:{
                //             lineStyle:{
                //                 color:'#fff',
                //                 width:2
                //             }
                //         }
                //     } ],
                //     series: [
                //         {
                //             name: '總人數',
                //             type: 'bar',
                //             barWidth:'80%',
                //             data: [460, 268, 716, 571, 625, 550, 846, 867, 952, 1128, 1253],
                //             label: {
                //                 show: false,
                //                 position: 'top',

                //             },
                //             bottom:200

                //         },{
                //             name: '總團數',
                //             type: 'line',
                //             data: [470, 280, 730, 720, 740, 750, 850, 870, 970, 1200, 1300],
                //             bottom:200
                //         }
                //     ],


                // });


            }
        }, 
        openNews(id){
            window.open('https://www.mingdao.edu.tw/md/200310/CLIP/show_clipping.php?CLIP_id='+id, '新聞剪影', 'height=500,width=700');
        }
    },
    mounted() {

        window.addEventListener('scroll', this.initChart);

        this.$nextTick(() => {
            setTimeout(() => {

            }, 1000)
        });
        new Swiper('.l-banner--slide', {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            // autoplay: true,
            speed: 1000,
            pagination: {
                el: '.l-banner--pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    },
});