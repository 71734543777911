import windowMixin from './window_mixin'

const bgstyleMixin = {
    mixins: [windowMixin],
    methods: {
        bgStyle(img) {
            if (img) return `background:url('${img}') center center / cover no-repeat;`
            return img
        },
        hexToRgbA(hex, opacity) {
            let c
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('')
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]]
                }
                c = `0x${c.join('')}`
                return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`
            }
            throw new Error('Bad Hex')
        },
        bgCursor(color) {
            return `background:radial-gradient(circle, ${this.hexToRgbA(color, 1)} 0%, ${this.hexToRgbA(color, 0.8)} 10%, ${this.hexToRgbA(color, 0)} 70%);`
        },
        bgBlend(img, color) {
            if (this.width >= 768) return `background:url('${img}') center center / cover no-repeat, ${this.hexToRgbA(color, 1)};`
            return `background:url('${img}') 70% bottom / cover no-repeat, ${this.hexToRgbA(color, 1)};`
        },
        bgColor(color, opacity) {
            return `background: ${this.hexToRgbA(color, opacity)};`
        },
    },
}
export default bgstyleMixin
