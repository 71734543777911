const notificationMixin = {
    data() {
        return {
            isNotification: false,
            notificationStatus: null,
            status: [
                // {
                //     title: '送出成功',
                //     content: '感謝您的來信，我們將有專人盡快與您聯繫！',
                //     btn: '確認',
                // },
                // {
                //     title: '送出失敗',
                //     content: '請檢查您的網路是否連接，並重新寄送！',
                //     btn: '關閉',
                // },
                {
                    title: 'Success',
                    content: 'Thank for your interest in Cervoz.',
                    btn: 'OK',
                },
                {
                    title: '訂閱失敗',
                    content: '您的信箱可能有誤或屬無效信箱，請重新輸入！',
                    btn: '關閉',
                }
            ],
        }
    },
    methods: {
        openNotification(params) {
            this.notificationStatus = this.status[params];
            this.isNotification = true
        },
        closeNotification() {
            this.isNotification = false
        },
    },
}
export default notificationMixin
