import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import windowMixin from './window_mixin'
import wheelStore from '../public/wheel_store'

gsap.registerPlugin(ScrollToPlugin)
gsap.defaults({ ease: 'sine.out' })

const wheelMixin = {
    mixins: [windowMixin, wheelStore],
    data() {
        return {
            WHEEL_TRANSITION_SPEED: 0.5,
            WHEEL_DELAY: 800,
            checkPage: false,
        }
    },
    watch: {
        currentStep(val) {
            const sections = document.querySelectorAll('section')
            if (val === 4) gsap.to(window, { duration: this.WHEEL_TRANSITION_SPEED, scrollTo: { y: `#${sections[val].id}` }, onStart: this.startScroll, onComplete: this.comss })
            else gsap.to(window, { duration: this.WHEEL_TRANSITION_SPEED, scrollTo: { y: `#${sections[val].id}` }, onStart: this.startScroll, onComplete: this.completeScroll })
        },
    },
    methods: {
        // throttle(fn, wait) {
        //     var time = Date.now();
        //     return function() {
        //       if ((time + wait - Date.now()) < 0) {
        //         fn();
        //         time = Date.now();
        //       }
        //     }
        // },
        handleWheelbyHeader(current, e) {
            if (this.checkPage && current === 0 && !this.isTransition && this.width >= 1440) this.slide(0, 1, e)
            if (this.checkPage && current === 1 && !this.isTransition && this.width >= 1440) this.slide(0, 2, e)
            if (this.checkPage && current === 2 && !this.isTransition && this.width >= 1440) this.slide(1, 3, e)
            if (this.checkPage && current === 3 && !this.isTransition && this.width >= 1440) this.slide(2, 4, e)
            if (this.checkPage && current === 4 && !this.isTransition && this.width >= 1440 && document.getElementById('video').getBoundingClientRect().top > 0) this.slide(3, 4, e)
            e.stopPropagation()
        },
        handleWheel(prev, next, e) {
            if (!this.isTransition && this.width >= 1440) this.slide(prev, next, e)
            e.stopPropagation()
        },
        handleWheelLast(prev, next, e) {
            if (this.checkPage && !this.isTransition && this.width >= 1440 && document.getElementById('video').getBoundingClientRect().top > 0) this.slide(prev, next, e)
            e.stopPropagation()
        },
        slide(prev, next, e) {
            if (e.detail > 0 || e.wheelDelta < 0) this.handleMove('tonext', next)
            if (e.detail > 0 || e.wheelDelta > 0) this.handleMove('toprev', prev)
        },
        slideTo(current, index) {
            if (current > index) this.handleMove('toprev', index)
            if (current < index) this.handleMove('tonext', index)
        },
        startScroll() {
            this.handleisMove(true)
            document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
        },
        comss() {
            this.handleMoveState('stop', false)
            document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
        },
        completeScroll() {
            setTimeout(() => {
                this.handleMoveState('stop', false)
                document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
            }, this.WHEEL_DELAY)
        },
    },
    mounted() {
        if (document.getElementById('index')) this.checkPage = true
    },
}
export default wheelMixin
